import ABIE from "../img/ABIE.png";
import ABIR from "../img/ABIR.png";
import ABOE from "../img/ABOE.png";
import ABOR from "../img/ABOR.png";
import ACIE from "../img/ACIE.png";
import ACIR from "../img/ACIR.png";
import ACOE from "../img/ACOE.png";
import ACOR from "../img/ACOR.png";
import DBIE from "../img/DBIE.png";
import DBIR from "../img/DBIR.png";
import DBOE from "../img/DBOE.png";
import DBOR from "../img/DBOR.png";
import DCIE from "../img/DCIE.png";
import DCIR from "../img/DCIR.png";
import DCOE from "../img/DCOE.png";
import DCOR from "../img/DCOR.png";

export const ResultData = [
  {
    id: 1,
    best: "ABIE",
    image: `${ABIE}`,
  },
  {
    id: 2,
    best: "ABIR",
    image: `${ABIR}`,
  },
  {
    id: 3,
    best: "ABOE",
    image: `${ABOE}`,
  },
  {
    id: 4,
    best: "ABOR",
    image: `${ABOR}`,
  },
  {
    id: 5,
    best: "ACIE",
    image: `${ACIE}`,
  },
  {
    id: 6,
    best: "ACIR",
    image: `${ACIR}`,
  },
  {
    id: 7,
    best: "ACOE",
    image: `${ACOE}`,
  },
  {
    id: 8,
    best: "ACOR",
    image: `${ACOR}`,
  },
  {
    id: 9,
    best: "DBIE",
    image: `${DBIE}`,
  },
  {
    id: 10,
    best: "DBIR",
    image: `${DBIR}`,
  },
  {
    id: 11,
    best: "DBOE",
    image: `${DBOE}`,
  },
  {
    id: 12,
    best: "DBOR",
    image: `${DBOR}`,
  },
  {
    id: 13,
    best: "DCIE",
    image: `${DCIE}`,
  },
  {
    id: 14,
    best: "DCIR",
    image: `${DCIR}`,
  },
  {
    id: 15,
    best: "DCOE",
    image: `${DCOE}`,
  },
  {
    id: 16,
    best: "DCOR",
    image: `${DCOR}`,
  },
];
