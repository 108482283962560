export const QuestionData = [
  {
    id: 1,
    title: "사람들과 함께할 때, 어떤 편이오?",
    answera: "다 같이 어울려 노는 것이 좋소.",
    answerb: "혼자만의 시간을 통해\n마음의 안정을 찾소.",
    type: "EI",
  },
  {
    id: 2,
    title: "여유 시간을 보낼 때,\n어떤 장소를 선호하오?",
    answera: "사람들이 북적이는 곳이\n내 취향이오.",
    answerb: "조용한 곳에서 사색을 즐기고 싶소.",
    type: "EI",
  },
  {
    id: 3,
    title: "손님께서 여행을 떠나신다면, \n누구와 함께하고 싶소?",
    answera: "가까운 벗이나 \n가족과 함께 가고 싶소.",
    answerb: "홀로 떠나는 것도 좋겠지.",
    type: "EI",
  },
  {
    id: 4,
    title: "새로운 일을 시작할 때, \n무엇을 중요하게 여기시오?",
    answera: "구체적인 계획과 \n현실적인 방안을 먼저 생각하오.",
    answerb: "큰 그림을 그리며\n가능성을 먼저 떠올리오.",
    type: "SN",
  },
  {
    id: 5,
    title: "주변을 볼 때,\n주로 무엇에 집중하오?",
    answera: "눈에 보이는 것에 집중하며\n 상황을 파악하오.",
    answerb: "보이지 않는 의미나\n 가능성에 마음이 가오.",
    type: "SN",
  },
  {
    id: 6,
    title: "손님께서 진귀한 물건을 보았을 때, \n어떤 생각이 드시오?",
    answera: "어떻게 만들어졌는지\n그 과정이 궁금하오.",
    answerb: "이 물건이 지닌 \n숨겨진 이야기가 궁금하오.",
    type: "SN",
  },
  {
    id: 7,
    title: "다른 사람의 말을 들을 때, \n어떤 점을 중시하오?",
    answera: "논리적이고 이성적인 부분에\n 귀를 기울이오.",
    answerb: "감정과 공감에 더 마음이 가오.",
    type: "TF",
  },
  {
    id: 8,
    title: "손님께서 갈등 상황에 처했을 때, \n어떻게 해결하고 싶소?",
    answera: "문제의 원인을 파악하고 \n합리적으로 해결하고 싶소.",
    answerb: "상대방의 감정을 고려하여 \n원만히 해결하고 싶소.",
    type: "TF",
  },
  {
    id: 9,
    title: "손님께서 어떤 보상을 받았을 때, \n어떤 느낌이 드시오?",
    answera: "보상 자체보다도 \n그 과정이 중요하다고 생각하오.",
    answerb: "보상에 대해 기쁘고\n 감사한 마음이 가장 크오.",
    type: "TF",
  },
  {
    id: 10,
    title: "중요한 계획을 세운다면,\n어떻게 준비하시겠소?",
    answera: "미리 철저하게 준비하고 계획하오.",
    answerb: "상황에 따라 유연하게 \n대처하고자 하오.",
    type: "JP",
  },
  {
    id: 11,
    title: "일정을 어떻게 관리하시오?",
    answera: "미리 계획하여 \n정해진 일정대로 움직이오.",
    answerb: "그때그때 상황에 맞추어 \n유동적으로 움직이오.",
    type: "JP",
  },
  {
    id: 12,
    title: "여행을 마치고 돌아온 후에는\n어떻게 하시오?",
    answera: "바로 짐을 정리하여 \n깔끔하게 마무리하오.",
    answerb: "피곤하니 짐은 나중에 \n정리하곤 하오.",
    type: "JP",
  },
];
