import React from "react";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ResultData } from "./data/resultdata";
import "./ZombieResult.css";
import ShareButton from "./ShareButton";
import { MoonLoader } from "react-spinners";
import ReactGA from "react-ga";
import Take5 from "../../../../components/Take5/Take5";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import MomCoupangBanner from "../../MomCoupangBanner/MomCoupangBanner";

const ZombieResult = () => {
  const { mbti } = useParams();
  const [resultData, setResultData] = useState({});
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "zombie";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    // 결과를 찾을 때 동적으로 읽어온 MBTI 유형을 사용
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".Mom_All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  useEffect(() => {
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  return (
    <>
      <div className="zombie_Hash">
        {loading ? (
          <MoonLoader
            color={"#ff3838"}
            loading={loading}
            size={100}
            cssOverride={{
              marginTop: "15rem",
              marginBottom: "20rem",
              backgroundColor: "transparent",
            }}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <div className="Zombie_R_Wrapper">
            <Take5 />
            {hasCookie ? (
              <>
                <div className="Mom_All_Test_has_cookie">
                  <div className="zombie_MainTitle">좀비월드 MBTI</div>
                  <div className="zombie_Header">내 유형은...</div>

                  <div className="zombie_Contents">
                    <div className="zombie_Back">
                      <div className="zombie_result_title">
                        {resultData.name}
                      </div>
                      <div className="zombie_resultImg_div">
                        <img src={resultData.image} alt="" />
                      </div>
                      <div className="zombie_result_subTitle">
                        {resultData.subname}
                      </div>
                      <div className="zombie_tag">
                        <div className="zombie_desc">{resultData.desc}</div>
                        <div className="zombie_desc1">{resultData.desc1}</div>
                        <div className="zombie_desc2">{resultData.desc2}</div>
                      </div>
                    </div>
                    <div className="zombie_details">
                      <div className="zombie_detail_container">
                        <h4 className="zombie_text_head">
                          🙋🏻 저는 이런 성격이에요
                        </h4>
                        <div className="zombie_deatil">{resultData.detail}</div>
                      </div>
                      <div className="zombie_detail_container">
                        <h4 className="zombie_text_head">
                          💜 이런 걸 좋아해요
                        </h4>
                        <div className="zombie_deatil1">
                          {resultData.detail1}
                        </div>
                      </div>
                      <div className="zombie_detail_container">
                        <h4 className="zombie_text_head">
                          💔 이런 걸 특히 싫어해요
                        </h4>
                        <div className="zombie_deatil2">
                          {resultData.detail2}
                        </div>
                      </div>
                    </div>

                    <div className="test_btn">
                      <div
                        className="zombie_return_btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/zombie")}
                      >
                        테스트 다시하기
                      </div>
                    </div>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="Mom_All_Test_no_cookie_container">
                  <div className="Mom_All_Test_no_cookie">
                    <div className="zombie_MainTitle">좀비월드 MBTI</div>
                    <div className="zombie_Header">내 유형은...</div>
                    <div className="zombie_Mom_coupangDiv">
                      <MomCoupangBanner handleClick={handleClick} />
                    </div>

                    {showResult && (
                      <div className="zombie_Contents">
                        <div className="zombie_Back">
                          <div className="zombie_result_title">
                            {resultData.name}
                          </div>
                          <div className="zombie_resultImg_div">
                            <img src={resultData.image} alt="" />
                          </div>

                          <div className="zombie_result_subTitle">
                            {resultData.subname}
                          </div>
                          <div className="zombie_tag">
                            <div className="zombie_desc">{resultData.desc}</div>
                            <div className="zombie_desc1">
                              {resultData.desc1}
                            </div>
                            <div className="zombie_desc2">
                              {resultData.desc2}
                            </div>
                          </div>
                        </div>
                        <div className="zombie_details">
                          <div className="zombie_detail_container">
                            <h4 className="zombie_text_head">
                              🙋🏻 저는 이런 성격이에요
                            </h4>
                            <div className="zombie_deatil">
                              {resultData.detail}
                            </div>
                          </div>
                          <div className="zombie_detail_container">
                            <h4 className="zombie_text_head">
                              💜 이런 걸 좋아해요
                            </h4>
                            <div className="zombie_deatil1">
                              {resultData.detail1}
                            </div>
                          </div>
                          <div className="zombie_detail_container">
                            <h4 className="zombie_text_head">
                              💔 이런 걸 특히 싫어해요
                            </h4>
                            <div className="zombie_deatil2">
                              {resultData.detail2}
                            </div>
                          </div>
                        </div>

                        <div className="test_btn">
                          <button
                            className="zombie_return_btn"
                            style={{
                              minWidth: "210px",
                              maxWidth: "210px",
                              minHeight: "50px",
                              boxShadow: "none",
                            }}
                            onClick={() => navigate("/test/zombie")}
                          >
                            테스트 다시하기
                          </button>
                        </div>
                        <ShareButton />
                        <div className="TestList_container">
                          {filteredTestList.map((test) => (
                            <div key={test.id} className="TestList_container2">
                              <Link to={`/test/${test.id}`}>
                                <Card test={test} />
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ZombieResult;
