import React from "react";
import "./TestpingR.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom"; // useLocation 추가
import { useState, useEffect } from "react";
import { ResultData } from "./data/ResultData"; // ResultData를 우리가 만든 성향 결과 데이터로 수정
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

const TestpingR = () => {
  const { resultType } = useParams(); // mbti 대신 resultType으로 변경
  const [resultData, setResultData] = useState({});
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "testping";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    // ResultData에서 resultType에 해당하는 데이터를 가져옴
    const result = ResultData.find((s) => s.best === resultType); // best로 비교
    setResultData(result);
  }, [resultType]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  return (
    <>
      <div className="testping_R_Wrapper">
        {loading ? (
          <div className="testping_loading_container">
            <p className="testping_loading_text">스캔중...🤔</p>
            <SyncLoader
              color={"#dd3e98"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <CoupangPromotionCP />
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="testping_R_Contents">
                    <h2 className="testping_R_title">나랑 닮은 핑은...</h2>
                    <div
                      className="testping_R_ResultImg_Container"
                      id="coupangDiv"
                    >
                      <img src={resultData?.image} alt={resultType} />
                    </div>
                    <div className="testping_IC_container">
                      <InnerCoupang />
                    </div>
                    <div className="testping_R_Restart_Btn_Container">
                      <button
                        className="testping_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/testping")}
                      >
                        테스트 다시하기
                      </button>
                    </div>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="All_Test_no_cookie_container">
                  <div className="All_Test_no_cookie">
                    <div className="testping_R_Contents">
                      <h2 className="testping_R_title">나랑 닮은 핑은...</h2>
                      <div
                        className="testping_R_ResultImg_Container"
                        id="coupangDiv"
                      >
                        <img src={resultData?.image} alt={resultType} />
                      </div>
                      <div className="testping_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                      </div>
                      {showResult && (
                        <>
                          <div className="testping_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="testping_R_Restart_Btn_Container">
                            <button
                              className="testping_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/testping")}
                            >
                              테스트 다시하기
                            </button>
                          </div>
                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TestpingR;
