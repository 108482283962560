import React, { useState, useEffect } from "react";
import "./KoreaColorQ.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "./data/QuestionData";
import Take5 from "../../../../components/Take5/Take5";
import backImg from "./img/BackImg.png";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const KoreaColorQ = () => {
  const shuffledQuestions = React.useMemo(
    () => shuffleArray([...QuestionData]),
    []
  );
  const [questionNo, setQuestionNo] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [totalScore, setTotalScore] = useState([
    { id: "EI", score: 0 },
    { id: "SN", score: 0 },
    { id: "TF", score: 0 },
    { id: "JP", score: 0 },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    if (questionNo === 0) {
      // 첫 번째 질문에만 애니메이션을 적용
      setShowOptions(false);
      const timer = setTimeout(() => {
        setShowOptions(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      // 이후 질문은 애니메이션 없이 바로 표시
      setShowOptions(true);
    }
  }, [questionNo]);

  const handleClickButton = (no, type) => {
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + no } : s
    );

    setTotalScore(newScore);

    if (shuffledQuestions.length !== questionNo + 1) {
      setQuestionNo(questionNo + 1);
    } else {
      const mbti = newScore.reduce(
        (acc, curr) =>
          acc +
          (curr.score >= 2 ? curr.id.substring(0, 1) : curr.id.substring(1, 2)),
        ""
      );
      navigate(`/test/krcolor/result/${mbti}`);
    }
  };

  return (
    <div className="krcolor_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / shuffledQuestions.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "🐲",
            color: "#bc9271b6",
          },
        }}
      />
      <div className="krcolor_Q_BackImg">
        <img src={backImg} alt="rp_back" />
      </div>
      <div className="krcolor_Q_Contents">
        <div className={`krcolor_Q_Title ${questionNo === 0 ? "fade-in" : ""}`}>
          {shuffledQuestions[questionNo].title}
        </div>
        {showOptions && (
          <div className="krcolor_Q_Btn_Container">
            <button
              className="krcolor_Q_Btn"
              onClick={() =>
                handleClickButton(1, shuffledQuestions[questionNo].type)
              }
              type="button"
            >
              {shuffledQuestions[questionNo].answera}
            </button>
            <button
              className="krcolor_Q_Btn"
              onClick={() =>
                handleClickButton(0, shuffledQuestions[questionNo].type)
              }
              type="button"
            >
              {shuffledQuestions[questionNo].answerb}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default KoreaColorQ;
