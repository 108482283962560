export const QuestionData = [
  {
    id: 1,
    title: "새 프로젝트를 맡게 되었을 때, \n너의 반응은?",
    answera: "일단 바로 시작하면서 \n진행해",
    answerb: "주변 의견을 듣고 \n신중히 계획을 세워",
    type: "AD", // 주도형 vs 숙고형
  },
  {
    id: 2,
    title: "여행 계획을 짤 때, 너는?",
    answera: "내가 주도적으로 계획을 세우고 \n친구들에게 말해",
    answerb: "다른 사람들의 의견을 충분히 \n반영해서 결정해",
    type: "AD", // 주도형 vs 숙고형
  },
  {
    id: 3,
    title: "일정을 맞추기가 어려운 친구가 있어, \n넌 어떻게 할래?",
    answera: "내가 주도적으로 친구에게 \n새로운 일정을 제안해",
    answerb: "친구의 의견을 존중해 \n함께 해결책을 찾아",
    type: "AD", // 주도형 vs 숙고형
  },
  {
    id: 4,
    title: "모험적인 활동을 할 기회가 생겼을 때, \n넌?",
    answera: "리스크를 감수하더라도 \n한번 도전해봐",
    answerb: "위험성을 먼저 따져보고 \n신중하게 결정해",
    type: "BC", // 대담한 vs 신중한
  },
  {
    id: 5,
    title: "새로운 아이디어가 떠올랐어, \n넌 어떻게 해?",
    answera: "바로 실행에 옮기면서 \n진행해!",
    answerb: "충분히 검토하고 \n계획을 세운 후 시작해!",
    type: "BC", // 대담한 vs 신중한
  },
  {
    id: 6,
    title: "갑작스런 변화가 생겼을 때, \n넌?",
    answera: "바로 대응하고 \n상황에 맞춰 대처해",
    answerb: "상황을 분석한 후 \n대응책을 찾아",
    type: "BC", // 대담한 vs 신중한
  },
  {
    id: 7,
    title: "혼자 일할 때와 팀으로 일할 때, \n어느 쪽이 더 효율적이야?",
    answera: "혼자서 하는 게 더 \n효율적이라고 생각해",
    answerb: "팀으로 협력하며 \n해결하는 게 더 좋아",
    type: "IO", // 개인적 vs 공동체적
  },
  {
    id: 8,
    title: "문제가 생겼을 때, \n넌 어떻게 해결하려고 해?",
    answera: "혼자서 해결책을 찾으려고 해",
    answerb: "다른 사람들과 함께 \n아이디어를 모아 해결해",
    type: "IO", // 개인적 vs 공동체적
  },
  {
    id: 9,
    title: "새로운 아이디어를 발전시킬 때, \n넌?",
    answera: "혼자서 구체화한 후 \n실행에 옮겨",
    answerb: "팀원들과 아이디어를 나누고 \n함께 발전시켜",
    type: "IO", // 개인적 vs 공동체적
  },
  {
    id: 10,
    title: "중요한 결정을 내릴 때, \n넌 무엇을 더 중요시해?",
    answera: "내 감정과 직감을 \n따라 결정해",
    answerb: "논리적으로 분석하고 \n객관적인 데이터를 따져",
    type: "ER", // 감정적 vs 이성적
  },
  {
    id: 11,
    title: "친구가 고민을 털어놨을 때, \n넌 어떻게 반응해?",
    answera: "먼저 친구의 감정에 공감하고 \n 얘기를 다 들어줘",
    answerb: "먼저 얘기를 들은 후\n같이 방법을 찾는 편이야",
    type: "ER", // 감정적 vs 이성적
  },
  {
    id: 12,
    title: "상황이 복잡할 때, \n넌 어떻게 접근해?",
    answera: "내 감정을 존중하면서 \n상황을 해결해",
    answerb: "객관적이고 이성적으로 \n상황을 분석해",
    type: "ER", // 감정적 vs 이성적
  },
];
