import React from "react";
import "./ShareButton.css";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useScript } from "./Hook.js";
import { useEffect } from "react";
import kakaoLogo from "./img/kakako.png";
import share from "./img/share.png";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import kakaoCh from "../../../../img/kakaoCh.png";

const Sawl = () => {
  require("sweetalert2");
  Swal.fire("복사 완료!");
};

function ShareButton() {
  const currentUrl = "https://zombiembti.net/test/inmyhead";
  const testTitle = "요즘 내 머릿속 테스트 | 테이크파이브 테스트";

  // kakao SDK import하기
  const status = useScript("https://developers.kakao.com/sdk/js/kakao.js");
  // kakao sdk 초기화하기
  // status가 변경될 때마다 실행되며, status가 ready일 때 초기화를 시도합니다.
  useEffect(() => {
    if (status === "ready" && window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init("8acc2dcb3d80526c8b24eebe78c9234c");
      }
    }
  }, [status]);

  const addKakaoChannel = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init("8acc2dcb3d80526c8b24eebe78c9234c");
      }

      // 카카오톡 채널 액션 실행
      kakao.Channel.addChannel({
        channelPublicId: "_AUZxgxj",
      });
    }
  };

  const kakaoButton = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init("8acc2dcb3d80526c8b24eebe78c9234c");
      }

      kakao.Share.sendDefault({
        objectType: "feed",
        content: {
          title: "요즘 내 머릿속 테스트 | 테이크파이브 테스트",
          description: "나도 모르는 내 머릿속 TMI는?",
          imageUrl:
            "https://snackcat.click/static/media/Inmyhead_sample.14dee1132a397ebf97bd.png",
          link: {
            mobileWebUrl: "https://zombiembti.net/test/inmyhead",
            webUrl: "https://zombiembti.net/test/inmyhead",
          },
        },
        buttons: [
          {
            title: "테스트하기",
            link: {
              mobileWebUrl: "https://zombiembti.net/test/inmyhead",
              webUrl: "https://zombiembti.net/test/inmyhead",
            },
          },
        ],
      });
    }
  };

  return (
    <>
      <div className="FlexContainer">
        <div className="KakaoShareButton" onClick={kakaoButton}>
          <img src={kakaoLogo} alt="kakaoLogo" />
        </div>
        <CopyToClipboard text={currentUrl}>
          <div className="URLShareButton" onClick={Sawl}>
            <img src={share} alt="url" />
          </div>
        </CopyToClipboard>
        <div className="GridContainer">
          <FacebookShareButton
            style={{ marginRight: "20px", marginTop: "5px" }}
            url={currentUrl}
            quote={testTitle}
            hashtag={"#" + testTitle.replace(/\s/g, "")}
          >
            <FacebookIcon
              size={32}
              round={true}
              borderRadius={24}
            ></FacebookIcon>
          </FacebookShareButton>

          <TwitterShareButton
            style={{ marginRight: "20px", marginTop: "5px" }}
            url={currentUrl}
            title={testTitle}
            hashtags={[
              "요즘내머릿속테스트",
              "mbti테스트",
              "성격테스트",
              "유형테스트",
              "테이크파이브테스트",
            ]}
          >
            <TwitterIcon size={32} round={true} borderRadius={24}></TwitterIcon>
          </TwitterShareButton>
        </div>
        <div className="kakao_channel_container">
          <p className="kakao_channel_p">새 테스트 소식이 궁금하다면?👀✨</p>
          <div className="kakao_channel_icon" onClick={addKakaoChannel}>
            <img src={kakaoCh} alt="kakaoch" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ShareButton;
