export const QuestionData = [
  {
    id: 1,
    title: "집에서 혼자 밥을 먹게 됐어, \n넌 어떻게 해?",
    answera: "누군가에게 전화를 걸거나 \n영상통화를 하며 먹는다",
    answerb: "좋아하는 드라마나 \n영화를 틀어놓고 먹는다",
    type: "EI",
  },
  {
    id: 2,
    title: "요즘 머릿속에 제일 \n자주 떠오르는 생각은?",
    answera: "사람들과 어울려 놀고 싶은데,\n어디 갈까?",
    answerb: "조용히 혼자 쉬고 싶은데,\n뭐 할까?",
    type: "EI",
  },
  {
    id: 3,
    title: "친구와 함께 시간을 보낼 때,\n넌 어떤 스타일이야?",
    answera: "많은 사람들과 활기차게 \n어울리는 게 좋아",
    answerb: "몇 명과 함께 조용히 \n대화 나누는 걸 좋아해",
    type: "EI",
  },
  {
    id: 4,
    title: "새로운 음식점이 생겼을 때, \n넌 어떤 타입이야?",
    answera: "리뷰를 읽고 괜찮으면 가봐",
    answerb: "정보 없이 그냥 가서 경험해!",
    type: "SN",
  },
  {
    id: 5,
    title: "요즘 머릿속에 자주 떠오르는 고민은?",
    answera: "당장 해결해야 할 \n현실적인 문제들",
    answerb: "미래에 일어날지 모를 \n상황들에 대한 상상",
    type: "SN",
  },
  {
    id: 6,
    title: "옷을 고를 때 넌 어떤 스타일이야?",
    answera: "가격표와 재질, 세탁 방법까지\n 꼼꼼히 따진다",
    answerb: "이 옷을 입었을 때 \n내 느낌과 분위기를 상상한다",
    type: "SN",
  },
  {
    id: 7,
    title: "요즘 내 감정 상태는?",
    answera: "상황에 따라 차분하게 판단하는 중",
    answerb: "감정이 왔다 갔다 하면서 고민하는 중",
    type: "TF",
  },
  {
    id: 8,
    title: "힘든 일이 생기면, 넌 어떻게 해?",
    answera: "문제를 해결할 방법을 찾아",
    answerb: "누군가에게 마음을 털어놔",
    type: "TF",
  },
  {
    id: 9,
    title: "고민이 생겼을 때 넌 어떤 타입이야?",
    answera: "상황을 차분히 분석해서 \n해결하려고 해",
    answerb: "먼저 내 감정을 돌보고 나서 \n해결책을 생각해",
    type: "TF",
  },
  {
    id: 10,
    title: "휴일 아침, 눈을 떴는데 \n날씨가 완전 좋아! 뭐 할래?",
    answera: "일단 집안일이나 밀린 일들을 처리하고 \n나갈 준비를 한다",
    answerb: "즉흥적으로 친구에게 연락해서 \n놀러 나간다",
    type: "JP",
  },
  {
    id: 11,
    title: "계획이 틀어지면 \n넌 어떻게 해?",
    answera: "다시 계획을 세워서 차근차근 진행해",
    answerb: "그 때 그 때 상황에 맞춰 대처해",
    type: "JP",
  },
  {
    id: 12,
    title: "친구들과의 약속이 갑자기 취소됐어, \n넌 어떻게 해?",
    answera: "그 시간에 할 다른 일들을 \n바로 정리해둔다",
    answerb: "뜻밖의 자유 시간을 \n느긋하게 즐긴다",
    type: "JP",
  },
];
