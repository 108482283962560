import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./NojamResult.css";
import Footer from "../../../../components/Footer/Footer";
import { MoonLoader } from "react-spinners";
import ShareButton from "./ShareButton";
import template2 from "./img/template2.png";
import Take5 from "../../../../components/Take5/Take5";
import lv1 from "./img/lv1.png";
import lv2 from "./img/lv2.png";
import lv3 from "./img/lv3.png";
import lv4 from "./img/lv4.png";
import lv5 from "./img/lv5.png";
import lv6 from "./img/lv6.png";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
import MomCoupangBanner from "../../MomCoupangBanner/MomCoupangBanner";

function NojamResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "nojamlife";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  const reloadBtn = () => {
    navigate("/test/nojamlife");
  };

  const percentage = location.state?.percentage; // state 값이 있는지 확인합니다.

  let result = ""; // 결과를 저장할 변수

  // percentage 값에 따라 결과 변수(result)에 저장
  if (percentage >= 0 && percentage <= 4) {
    result = <img src={lv1} alt="lv1" />;
  } else if (percentage >= 5 && percentage <= 10) {
    result = <img src={lv2} alt="lv2" />;
  } else if (percentage >= 11 && percentage <= 30) {
    result = <img src={lv3} alt="lv3" />;
  } else if (percentage >= 31 && percentage <= 50) {
    result = <img src={lv4} alt="lv4" />;
  } else if (percentage >= 51 && percentage <= 70) {
    result = <img src={lv5} alt="lv5" />;
  } else if (percentage >= 71 && percentage <= 100) {
    result = <img src={lv6} alt="lv6" />;
  }

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".Mom_All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100vh";
  };

  return (
    <>
      <div className="Nojam_all_container">
        {loading ? (
          <>
            <MoonLoader
              color={"#595f88"}
              loading={loading}
              size={50}
              cssOverride={{
                marginTop: "3rem",
                marginBottom: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <CoupangPromotionCP />
          </>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="Mom_All_Test_has_cookie">
                  <div className="Nojam_R_backImg">
                    <img src={template2} alt="template" />
                  </div>
                  <div className="Nojam_R_container">
                    <h4 className="Nojam_R_title">인생 노잼력 테스트</h4>
                    <h2 className="Nojam_R_text">
                      지금 인생 노잼력은.... <br />
                      <br />
                      <span className="Nojam_NojamP">{percentage}%</span>
                    </h2>
                  </div>
                  <p className="Nojam_R_desc">{result}</p>

                  <div className="Nojam_Btn_container">
                    <button className="Nojam_reload_btn1" onClick={reloadBtn}>
                      다시하기
                    </button>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="Mom_All_Test_no_cookie_container">
                  <div className="Mom_All_Test_no_cookie">
                    <div className="Nojam_R_backImg">
                      <img src={template2} alt="template" />
                    </div>
                    <div className="Nojam_R_container">
                      <h4 className="Nojam_R_title">인생 노잼력 테스트</h4>

                      <h2 className="Nojam_R_text">
                        지금 인생 노잼력은.... <br />
                        <br />
                        <span className="Nojam_NojamP">{percentage}%</span>
                      </h2>
                    </div>
                    <div className="Nojam_Mom_coupangDiv">
                      <MomCoupangBanner handleClick={handleClick} />
                    </div>
                    {showResult && (
                      <div className="Nojam_showResult">
                        <p className="Nojam_R_desc">{result}</p>

                        <div className="Nojam_Btn_container">
                          <button
                            className="Nojam_reload_btn1"
                            onClick={reloadBtn}
                          >
                            다시하기
                          </button>

                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default NojamResult;
