import React from "react";
import "./InmyheadR.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"; // useLocation 추가
import { useState, useEffect } from "react";
import { ResultData } from "./data/ResultData";
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

const InmyheadR = () => {
  const { mbti } = useParams();
  const location = useLocation(); // useLocation으로 전달된 state 가져오기
  const { name } = location.state || {}; // 전달된 이름을 받아옴
  const [resultData, setResultData] = useState({});
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "inmyhead";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  return (
    <>
      <div className="inmyhead_R_Wrapper">
        {loading ? (
          <div className="inmyhead_loading_container">
            <p className="inmyhead_loading_text">스캔중...🔎</p>
            <SyncLoader
              color={"#008ab0"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <CoupangPromotionCP />
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="inmyhead_R_Contents">
                    <h2 className="inmyhead_R_title">{name}의 머릿속은...</h2>{" "}
                    <div
                      className="inmyhead_R_ResultImg_Container"
                      id="coupangDiv"
                    >
                      <img src={resultData.image} alt="mbti" />
                    </div>
                    <div className="inmyhead_IC_container">
                      <InnerCoupang />
                    </div>
                    <div className="inmyhead_R_Restart_Btn_Container">
                      <button
                        className="inmyhead_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/inmyhead")}
                      >
                        테스트 다시하기
                      </button>
                    </div>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="All_Test_no_cookie_container">
                  <div className="All_Test_no_cookie">
                    <div className="inmyhead_R_Contents">
                      <h2 className="inmyhead_R_title">{name}의 머릿속은...</h2>{" "}
                      <div
                        className="inmyhead_R_ResultImg_Container"
                        id="coupangDiv"
                      >
                        <img src={resultData.image} alt="mbti" />
                      </div>
                      <div className="inmyhead_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                      </div>
                      {showResult && (
                        <>
                          <div className="inmyhead_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="inmyhead_R_Restart_Btn_Container">
                            <button
                              className="inmyhead_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/inmyhead")}
                            >
                              테스트 다시하기
                            </button>
                          </div>
                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InmyheadR;
