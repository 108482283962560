import React, { useState, useEffect } from "react";
import "./KoreaColorMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/MainImg.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { krcolorAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const KoreaColorMain = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/krcolor/question");
  };

  const id = "krcolor";

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const intervals = [
      setTimeout(() => setCurrentStep(1), 500),
      setTimeout(() => setCurrentStep(2), 1500),
      setTimeout(() => setCurrentStep(3), 3300),
      setTimeout(() => setCurrentStep(4), 4400),
    ];

    return () => intervals.forEach(clearTimeout);
  }, []);

  return (
    <div className="krcolor_wrapper">
      <div className="krcolor_contents">
        <SEO attribute={krcolorAttributeko} id={id} />
        <Take5 />
        <div className="krcolor_main_container">
          <img src={main} alt="main" />
          <div className="speech_bubble_container">
            {currentStep >= 1 && (
              <div className="speech_bubble">어서오시오~!</div>
            )}
            {currentStep >= 2 && (
              <div className="speech_bubble">
                한복을 빌리려는데 어떤 색이 좋을지 모르겠다라...
              </div>
            )}
            {currentStep >= 3 && (
              <div className="speech_bubble">
                내 찹쌀떡처럼 딱 맞는 색을 골라 드리리다!
              </div>
            )}
            {currentStep >= 4 && (
              <div className="speech_bubble">시작해봅시다!</div>
            )}
          </div>
          <button onClick={rpGotoQ} className="krcolor_start_btn_container">
            들어가기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default KoreaColorMain;
