import React, { useEffect, useState } from "react";
import "./InmyheadMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { inmyheadAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const InmyheadMain = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(""); // 이름을 저장하는 state

  const QPage = () => {
    if (name.trim() === "") {
      alert("이름을 입력해 주세요!"); // 이름이 없으면 알림 표시
    } else {
      navigate("/test/inmyhead/question", { state: { name } }); // 이름을 함께 전달
    }
  };

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const id = "inmyhead";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="inmyhead_wrapper">
      <div className="inmyhead_contents">
        <SEO attribute={inmyheadAttributeko} id={id} />
        <Take5 />
        <div className="inmyhead_main_container">
          <img src={main} alt="main" />
          <div className="inmyhead_input_container">
            <input
              type="text"
              placeholder="이름을 입력해 주세요"
              value={name}
              onChange={handleInputChange}
              className="inmyhead_name_input" // CSS를 위한 클래스명 추가
            />
          </div>
          <button onClick={QPage} className="inmyhead_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default InmyheadMain;
